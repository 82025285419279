





































































































































































































































































































































































































































































































































































































































































































































































































.school_region_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    text-transform: capitalize;
    width: 100%;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
  .el-form-item__content {
    text-align: initial;
    text-transform: capitalize;
  }
}
